import React from "react";
import { ThemeContext } from "../../context/themeContext";
import { useContext } from "react";

function About() {
  const { theme } = useContext(ThemeContext);

  return (
    <div className={theme}>
      <div className="about">
        <p className="text">
          Dynamic Software Engineer with over 3 years of experience specializing
          in Flutter application development and a strong understanding of
          various web technologies including ReactJS, Python, Django,
          JavaScript, and more...
          <br />
          Dedicated to building innovative mobile and web applications and
          services, I am now seeking to leverage my expertise and expand my
          career in a challenging environment.
        </p>
      </div>
    </div>
  );
}

export default About;
